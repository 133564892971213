@import "./assets/styles/scrollbar.css";
@import "./assets/styles/range.css";
@import "./assets/styles/cookiebot.css";

@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
    font-family: "PPRightGothic";
    src: url("assets/fonts/PPRightGothic-Medium.otf");
}

@font-face {
    font-family: "InterLight";
    src: url("assets/fonts/Inter-Light.ttf");
}

@font-face {
    font-family: "InterNormal";
    src: url("assets/fonts/Inter-Regular.ttf");
}

@font-face {
    font-family: "InterMedium";
    src: url("assets/fonts/Inter-Medium.ttf");
}

@font-face {
    font-family: "InterBold";
    src: url("assets/fonts/Inter-Bold.ttf");
}

@font-face {
  font-family: "InterBlack";
  src: url("assets/fonts/Inter-Black.ttf");
}

@layer base {
    html {
        font-family: InterNormal, system-ui, sans-serif;
    }
}

.conv-tail-question{
    position: relative;
}

.conv-tail-question::before{
    content: url("data:image/svg+xml,%3Csvg width='46' height='14' viewBox='0 0 46 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M45.3571 7.96239C44.3567 6.96214 36.8567 0.962069 36.8567 0.962069C36.8567 0.962069 25.8571 -0.537423 23.3573 0.961902C12.8574 10.4629 0.856232 12.9628 0.856232 12.9628C0.856232 12.9628 23.8567 16.4621 45.3571 7.96239Z' fill='%23E9E3D0'/%3E%3C/svg%3E%0A");
    width: 46px;
    height: 14px;
    position: absolute;
    bottom: 1px;
    left: -14px
}

.conv-tail-answer::before{
    content: url("data:image/svg+xml,%3Csvg width='45' height='14' viewBox='0 0 45 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M0.287444 7.96184C1.28783 6.96159 8.78783 0.961519 8.78783 0.961519C8.78783 0.961519 19.7874 -0.537972 22.2872 0.961353C32.7871 10.4623 44.7883 12.9623 44.7883 12.9623C44.7883 12.9623 21.7878 16.4616 0.287444 7.96184Z' fill='%230C2632'/%3E%3C/svg%3E");
    width: 45px;
    height: 14px;
    position: absolute;
    bottom: 1px;
    right: -14px;

    opacity: 0;
    animation: itemsConvTail 150ms ease-out;
    animation-delay: 150ms;
    animation-fill-mode: forwards;
}

.conv-tail-answer_no-delay::before{
    animation-delay: 0ms;
}

.items-conv{
    opacity: 0;
    animation: itemsConv 300ms ease-out;
    animation-fill-mode: forwards;
}

.chat-widget-hidden{
    display: none!important;
}

@keyframes itemsConv {
    0% {
        transform: translateY(20px);
        opacity: 0;
    }

    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

@keyframes itemsConvTail {
    0% {
        opacity: 0;
    }

    100% {
        opacity: 1;
    }
}

.conv-list > div{
    margin-bottom: 16px;
}

.conv-list > div:first-of-type{
    margin-bottom: 0;
}

.container-shadow, .container-flow-shadow{
    z-index: inherit;
    position: relative;
}

.container-shadow::after, .container-flow-shadow:after {
    border-radius: 16px 16px 300px 300px;
    box-shadow: rgba(0,0,0,.075) 0px 0px 10px 10px;
    content: '';
    display: block;
    height: 90%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
}

.container-flow-shadow:after {
    height: 300px;
}

.swiper-pagination-bullet {
  border: 1px solid #736F62 !important;
  background: transparent !important;
}

.swiper-pagination-bullet-active {
  background: #273F5C !important;
}

.swiper-pagination-bullet--fraction {
  border-width: 0 !important;
}

.swiper-pagination-bullet--fraction.swiper-pagination-bullet-active {
  background-color: transparent !important;
  border: 1px solid #0C2632 !important;
}

.swiper-pagination--reviews .swiper-pagination-bullet {
  width: 10px;
  height: 10px;
  margin-right: 10px;
  border: 1px solid #273F5C !important;
  opacity: 1;
}

.swiper-articles-filter .swiper-pagination {
  bottom: 80px !important;
}

@media (min-width: 1024px) {
  .swiper-articles-filter .swiper-pagination {
    bottom: 85px !important;
  }

  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
}

.swiper-pagination-progressbar .swiper-pagination-progressbar-fill {
  background: #CECABA !important;
}

.swiper-articles-filter .swiper-slide {
  margin-top: 0 !important;
}

.swiper-articles-seo .swiper-slide {
  height: auto !important;
}

.last-articles .article-grid-item {
  margin-bottom: 25px !important;
}

.is-sticky {
  position: fixed;
  top: 98px;
  z-index: 999;
  animation: 500ms ease-in-out 0s normal none 1 running fadeInDown;
}

.consent-radio label{
    font-family: 'InterMedium', 'rawline';
    max-width: 300px;
}

.consent-radio label:first-of-type + label{
    margin-bottom: 10px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type=number] {
    -moz-appearance: textfield;
}